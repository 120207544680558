//
// highlight.js
// Theme module
//


import hljs from 'highlight.js/lib/core';
import xml from 'highlight.js/lib/languages/xml';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';

document.addEventListener('DOMContentLoaded', function(){
    setTimeout(() => {
        const highlights = document.querySelectorAll('.highlight');
        // Only register the languages we need to reduce the download footprint
        hljs.registerLanguage('xml', xml);
        hljs.registerLanguage('javascript', javascript);
        hljs.registerLanguage('python', python);

        highlights.forEach(el => {
            hljs.highlightElement(el);
        });
    }, 100)
})
